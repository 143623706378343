import {
  DateTimeReference,
  OperatorResult,
  OperatorValue,
  Query,
  RepeatableSelection,
  SelectWithConditions,
  IAnswerResolver,
  CollectionInstanceIdentifiers,
  RepeatedAnswersBySurrogateId,
  Timezone,
  ApplicationContext,
} from '@breathelife/types';

import { evaluateConditions } from './evaluateConditions';
import { queryOperators } from './queryOperators';

export const SUB_QUERY_RESULT_KEY = 'subQueryResult';

export const NON_NODE_ID_TO_KEEP: unknown[] = [...Object.values(DateTimeReference)];

export function evaluateQuery(
  query: Query,
  values: Record<string, any>,
  resolver: IAnswerResolver,
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone,
  applicationContext: ApplicationContext = {},
): OperatorResult {
  let subQueryResult;
  if (query.subQuery) {
    subQueryResult = evaluateQuery(
      query.subQuery,
      values,
      resolver,
      repeatedInstanceIdentifiers,
      timezone,
      applicationContext,
    );
  }

  const queryValues: { [key: string]: OperatorValue } = getQueryValues(
    query,
    values,
    resolver,
    repeatedInstanceIdentifiers,
    timezone,
  );

  if (typeof subQueryResult !== 'undefined') {
    if (typeof subQueryResult === 'boolean') {
      throw new Error('non-numeric values are not supported in subQuery results');
    }

    queryValues[SUB_QUERY_RESULT_KEY] = subQueryResult;
  }

  const operatorFunction = queryOperators(timezone, applicationContext)[query.operator];
  if (!operatorFunction) {
    throw new Error(`Invalid query operator ${query.operator}`);
  }

  const augmentedOperatorParams = {
    ...(query.operatorParams || {}),
    timezone,
    applicationContext,
  };

  return operatorFunction(queryValues, augmentedOperatorParams);
}

function getQueryValues(
  query: Query,
  values: Record<string, any>,
  resolver: IAnswerResolver,
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone,
): { [key: string]: OperatorValue } {
  const queryValues = query.select.reduce((selectedValues: Record<string, any>, nodeIdOrConstant: string) => {
    if (NON_NODE_ID_TO_KEEP.includes(nodeIdOrConstant)) {
      selectedValues[nodeIdOrConstant] = nodeIdOrConstant;
    } else {
      selectedValues[nodeIdOrConstant] = resolver.getAnswer(values, nodeIdOrConstant, repeatedInstanceIdentifiers);
    }
    return selectedValues;
  }, {});

  const repeatableQueryValues = getRepeatableQueryValues(
    values,
    resolver,
    query.selectRepeatable,
    repeatedInstanceIdentifiers,
    timezone,
  );

  const conditionalValues = getConditionalQueryValues(
    values,
    resolver,
    query.selectWithConditions,
    repeatedInstanceIdentifiers,
    timezone,
  );

  return {
    ...queryValues,
    ...repeatableQueryValues,
    ...conditionalValues,
  };
}

/** Get the values specified by selectRepeatable  */
function getRepeatableQueryValues(
  values: Record<string, any>,
  resolver: IAnswerResolver,
  repeatableSelection: RepeatableSelection[] | undefined,
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone,
): { [key: string]: OperatorValue } {
  if (!repeatableSelection?.length) return {};

  const repeatableQueryValues = repeatableSelection.reduce(
    (
      selectedValues: { [key: string]: OperatorValue },
      { fromCollection, select: selectNodeId, selectWithConditions },
    ) => {
      if (selectNodeId) {
        const repeatedValuesBySurrogateId = resolver.getRepeatedAnswers(
          values,
          fromCollection,
          [selectNodeId],
          repeatedInstanceIdentifiers,
        );

        if (repeatedValuesBySurrogateId) {
          // The keys in the RepeatedAnswersBySurrogateId data structure are not needed here.
          const valuesWithRepeatedIndex = getRepeatedAnswerValuesWithIndex(selectNodeId, repeatedValuesBySurrogateId);

          for (const { index, value } of valuesWithRepeatedIndex) {
            // Selected values are stored with a key for use in formulas.
            selectedValues[createRepeatableNodeIdKey(fromCollection, index, selectNodeId)] = value;
          }
        }
      }

      if (selectWithConditions) {
        const { nodeId } = selectWithConditions;
        const conditionalRepeatedNodeIdValues = getConditionalRepeatedQueryValues(
          values,
          resolver,
          fromCollection,
          selectWithConditions,
          repeatedInstanceIdentifiers,
          timezone,
        );
        if (conditionalRepeatedNodeIdValues) {
          const valuesWithRepeatedIndex = getRepeatedAnswerValuesWithIndex(nodeId, conditionalRepeatedNodeIdValues);

          for (const { index, value } of valuesWithRepeatedIndex) {
            selectedValues[createRepeatableNodeIdKey(fromCollection, index, nodeId)] = value;
          }
        }
      }

      return selectedValues;
    },
    {},
  );

  return repeatableQueryValues;
}

function createRepeatableNodeIdKey(fromCollection: string, index: number, nodeId: string): string {
  // Note: queryOperators.fetch relies on keys being returned in this form.
  return `${fromCollection}-${index}-_-${nodeId}`;
}

function getRepeatedAnswerValuesWithIndex<T extends string>(
  selectNodeId: T,
  repeatedValuesBySurrogateId: RepeatedAnswersBySurrogateId<T>,
): { value: any; index: number }[] {
  const valuesWithRepeatedIndex = Object.values(repeatedValuesBySurrogateId).map((item) => {
    if (Object.keys(item.answersByNodeId).length > 1) {
      throw Error('expected repeated answers length of at most 1 (are the collection items missing surrogate ids?)');
    }

    return {
      value: item.answersByNodeId[selectNodeId],
      index: item.repeatedIndex,
    };
  });

  return valuesWithRepeatedIndex;
}

function getConditionalRepeatedQueryValues(
  values: Record<string, any>,
  resolver: IAnswerResolver,
  fromCollection: string,
  selectWithConditions: SelectWithConditions,
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone,
): RepeatedAnswersBySurrogateId<string> | undefined {
  const { nodeId, selectIf, defaultValue } = selectWithConditions;
  const repeatedValues = resolver.getRepeatedAnswers(values, fromCollection, [nodeId], repeatedInstanceIdentifiers);
  if (!repeatedValues) return undefined;

  const repeatedConditionalAnswersBySurrogateId: RepeatedAnswersBySurrogateId<string> = {};
  Object.keys(repeatedValues).forEach((surrogateId: string) => {
    const initialRepeatedValues = repeatedValues[surrogateId];

    const repeatedValueInstanceIdentifier = resolver.withCollectionIdentifier(
      repeatedInstanceIdentifiers,
      initialRepeatedValues.repeatedIndex,
      fromCollection,
    );
    const { isValid: nodeIdValuePassesSelectIf } = evaluateConditions(
      selectIf,
      values,
      resolver,
      repeatedValueInstanceIdentifier,
      timezone,
    );

    if (nodeIdValuePassesSelectIf) {
      repeatedConditionalAnswersBySurrogateId[surrogateId] = initialRepeatedValues;
    } else {
      repeatedConditionalAnswersBySurrogateId[surrogateId] = {
        ...initialRepeatedValues,
        answersByNodeId: { ...initialRepeatedValues.answersByNodeId, [nodeId]: defaultValue },
      };
    }
  });

  return repeatedConditionalAnswersBySurrogateId;
}

function getConditionalQueryValues(
  values: Record<string, any>,
  resolver: IAnswerResolver,
  selectWithConditions: SelectWithConditions[] | undefined,
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone,
): {
  [key: string]: OperatorValue;
} {
  if (!selectWithConditions?.length) return {};

  return selectWithConditions.reduce((selectedValues: any, conditionalNodeId: SelectWithConditions) => {
    const { nodeId, selectIf, defaultValue } = conditionalNodeId;
    const valueAtNodeId = resolver.getAnswer(values, nodeId, repeatedInstanceIdentifiers);
    const { isValid: nodeIdConditionHasBeenMet } = evaluateConditions(
      selectIf,
      values,
      resolver,
      repeatedInstanceIdentifiers,
      timezone,
    );
    selectedValues[nodeId] = nodeIdConditionHasBeenMet ? valueAtNodeId : defaultValue;
    return selectedValues;
  }, {});
}
